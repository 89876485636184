<script>
  import { getContext } from 'svelte';
  const {  locales, language  } = getContext('defaultSetting')

  const contactMethods = [
    {
      title: 'asiaContactTitle',
      content: '+63-9171485555',
      link: 'https://line.me/ti/p/~@889imnpv',
      target: '_blank'
    },
    {
      title:'eurContactTitle',
      content: '+44-7897897777',
      target: '_blank'
    }
  ]

</script>

<div class="contactMethods">
  {#each contactMethods as method}
    <div class="method">
     <p>{locales[language][method.title]}</p>
      <span>{method.content}</span>
    </div>
  {/each}
</div>

<style lang="scss">
  .contactMethods {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  justify-content: center;
  padding: 0 50px;
  .method {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #4c9eea;
    p{
      margin: 0px;
    }
    span {
      margin-left: 4px;
      color: #333333;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: 0;
    }
  }
}

@media screen and (min-width: 1024px) {
  .contactMethods {
    padding: 0;
  }
}
</style>