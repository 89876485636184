import { getEnv } from '@/config'
import navigatorLanguageToKey from '@/utils/navigatorLanguageToKey'
import locales from '@/Locales/locales.json'

const useHome = () => {
  let currentCountryInfo

  const get_Env_IpLocation = getEnv()?.IP_INFO || ''
  const languageByNavigator = navigatorLanguageToKey(window.navigator.language)
  const language = languageByNavigator || getEnv()?.SYSTEM.lang || 'en_US'

  const sendErrorMsg = () => {
    // wap
    window.parent && window.parent.postMessage({ type: 'on-error' }, '*')
    // ios
    window.dispatchEvent(new Event('on-error'))
    // android
    window.appInterface && window.appInterface.onError?.()
  }

  sendErrorMsg()

  return {
    currentCountryInfo,
    get_Env_IpLocation,
    locales,
    language
  }
}

export default useHome