<script>
  import { onMount } from 'svelte';
  import { getCustomerServiceUrl, getMerchantSettingApi } from '@/api/service'
  import { openService } from '../utils'
  import { getContext } from 'svelte';

  const { brand, domain, locales, language } = getContext('defaultSetting')
  let customerServiceUrl, merchantSetting

  const handleServiceClick = async() => {
    openService(customerServiceUrl, merchantSetting)
  }

  onMount(async () => {
    getMerchantSettingApi().then(data => (merchantSetting = data))
    getCustomerServiceUrl().then(data => (customerServiceUrl = data))
  })
</script>

<div class="customerService {brand}" on:click={handleServiceClick}>
  <img alt="" src={`${domain}images/${brand}/service.svg`} />
  <div>{locales[language].customerService}</div>
</div>

<style lang="scss">
  .customerService {
  min-width: 134px;
  width: fit-content;
  margin: 28px auto 20px;
  height: 38px;
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 13px;
  cursor: pointer;
  &.vd001, &.vd004, &.vd007, &.vd009 &.vd011 &.vd012 &.vd099 {
    color: #4c9eea;
    border: 1px solid #4c9eea;
  }
  &.vd002, &.vd003, &.vd005, &.vd006, &.vd008 {
    color: #0c186c;
    border: 1px solid #0c186c;
  }
  img {
    height: 20px;
    width: 20px;
    margin-right: 8px;
  }
  div {
    height: 14px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 14px;
  }
}

@media screen and (min-width: 1024px) {
  .customerService {
    min-width: auto;
    margin: 30px auto;
    height: 33px;
    border-radius: 22px;
  }
}
</style>