import { getConfig } from '../config'

export const getMerchantSettingApi = async () => {
	return await new Promise((resolve, reject) => {
		fetch(`${getConfig().PLATFORM_API_URL}/user/merchantSetting`, {
			method: 'GET'
		}).then(
			response => response.json()
		).then(
			data => resolve(data.data)
		).catch(
			error => reject(error)
		)
	})
}

export const getCustomerServiceUrl = async () => {
	return await new Promise((resolve, reject) => {
		fetch(`${getConfig().PLATFORM_API_URL}/sysmaintenance/customerService`, {
			method: 'GET'
		}).then(
			response => response.json()
		).then(
			data => resolve(data.data)
		).catch(
			error => reject(error)
		)
	})
}