<script>
  import useHome from "./useHome";
  import Logo from "./components/Logo.svelte"
  import BlockImage from './components/BlockImage.svelte';
  import RightContent from "./components/RightContent.svelte";
  import { setContext } from 'svelte';

  const domain = document.querySelector("#restrictions").src;
  const brand = process?.env?.ENV_BRAND_NAME || "vd001";
  const { get_Env_IpLocation, locales, language } = useHome();

  setContext('defaultSetting', {
    domain,
    brand,
    get_Env_IpLocation,
    locales,
    language
  })

  console.log("Current Version", process?.env?.ENV_VERSION);
  console.log("language", language);
</script>

<div class="wrapper">
  <div class="ipBlockContent">
    <div class="ipBlockContent__top">
      <Logo />
    </div>

    <div class="ipBlockContent__left">
      <BlockImage />
    </div>

    <div class="ipBlockContent__right">
      <RightContent />
    </div>
  </div>
</div>

<style lang="scss">
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  .ipBlockContent {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    width: 100%;
    &__top {
      width: 100%;
      display: flex;
      text-align: center;
      height: 32px;
      margin-top: 1.5rem;
    }
    &__left {
      width: 100%;
      max-width: 50vw;
      margin: 0 auto;
    }
    &__right {
      display: flex;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
      text-align: center;
      margin: 1.5rem 30px 0;
    }
  }
}

@media screen and (min-width: 700px) {
  .wrapper {
    .ipBlockContent {
      &__left {
        max-width: 350px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .wrapper {
    align-items: normal;
    .ipBlockContent {
      height: 100%;
      flex-direction: row;
      padding: 5rem 1rem;
      max-width: 1400px;
      flex-wrap: wrap;
      &__top {
        height: 40px;
        margin-top: 0;
      }
      &__left {
        width: 60%;
        max-width: none;
        height: auto;
        display: flex;
        align-items: center;
      }
      &__right {
        width: 40%;
        margin: 0;
      }
    }
  }
}
</style>