import navigatorLanguageToKey from '@/utils/navigatorLanguageToKey'
import { getConfig } from '@/config'
import locales from '@/Locales/locales.json'
import { sscoreLocaleByLocale } from '@/constants'

const isWap = (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase()))

export const detectDevice = () => isWap ? 'mobile' : 'desktop'

export const translate = (localeJson, locale) => (key) => {
  if (!localeJson || !locale) return key
  const current = localeJson.find((e) => e.key === key)?.[locale]
  return current || key
}

export const getCountryInfo = (localeJson) => {
  const languageByNavigator = navigatorLanguageToKey(window.navigator.language)
  const language = languageByNavigator || getConfig()?.SYSTEM.lang || 'en_US'
  const { country, province, city } = getConfig()?.IP_INFO || {}
  if (!country) return locales[language].unknown

  const isChina = ['中国', '中國', 'china'].includes(country)
  const t = translate(localeJson, language)
  const currentT = t(province) === t(city) ? t(province) : `${t(province)} ${t(city)}`
  return isChina ? currentT : t(country)
}

export const openService = (customerServiceUrl, merchantSetting) => {
  if (!customerServiceUrl || !customerServiceUrl?.length) return
  let index = 0
  const languageByNavigator = navigatorLanguageToKey(window.navigator.language)
  const language = languageByNavigator || getConfig()?.SYSTEM.lang || 'en_US'

  const hasBloc = customerServiceUrl.some(item => Number(item.customerServiceType) === 2)
  if (hasBloc) index = customerServiceUrl.findIndex(item => Number(item.customerServiceType) === 2)

  let url =  new URL(customerServiceUrl[index]?.customerServiceUrl.indexOf('http') === 0
  ? customerServiceUrl[index]?.customerServiceUrl
  : `http://${customerServiceUrl[index]?.customerServiceUrl}`)

  if (hasBloc) {
    const paramsObj = {
      ...(detectDevice() === 'desktop' && { isPC: 1 }),
      source: merchantSetting?.brandName,
      lang: sscoreLocaleByLocale[language || sscoreLocaleByLocale.zh_CN],
      ...(window?.__fingerPrint__?.visitorId && { deviceId: window?.__fingerPrint__?.visitorId })
    }
    Object.entries(paramsObj).forEach(item => url.searchParams.set(item[0], item[1]))

    url = `${url?.origin}${url?.hash}${url?.search}`
  }

  window.open(url)
}

const defaultParams = { key: '', extension: 'png' }

export const getLogoSrc = (params = defaultParams) => {
  const { key, extension = 'png', brand } = params
  if (!key || !extension || !brand) return ''
  return `${getConfig().FE_CDN_URL}/frontend/${getConfig().DEPLOY_ENV}/fe-images/${brand}/logo/${key}.${extension}`
}