<script>
  import { getLogoSrc } from '@/pages/Home/utils'
  import { getContext } from 'svelte';

  const { brand, language } = getContext('defaultSetting')

  let logoSrc
  
  switch (brand) {
    case 'vd005':
    case 'vd008':
      logoSrc = getLogoSrc({ brand, key: 'primary_c_logo' })
      break
    case 'vd007': {
      const useKey = ["zh_CN" ,"zh_HK"].includes(language) ? 'primary_zh_logo' : 'primary_logo'
      logoSrc = getLogoSrc({ brand, key: useKey })
      break
    }
    default:
      logoSrc = getLogoSrc({ brand, key: 'primary_logo' })
      break
  }
</script>

<img class="ipBlockLogo {brand}" alt="" src={logoSrc} />

<style lang="scss">
.ipBlockLogo {
  height: 100%;
  margin: 0 auto;
}
</style>