const navigatorLanguageToKey = browserLang => {
  const langMap = {
    en_US: ['en'],
    id_ID: ['id-id', 'id'],
    ms_MY: ['ms-my', 'ms'],
    vi_VN: ['vi-vn', 'vi'],
    zh_CN: ['zh'],
    zh_HK: ['zh-hk', 'zh-mo', 'zh-tw'],
    hi_IN: ['hi-in', 'hi'],
    ja_JP: ['ja-jp', 'ja'],
    ko_KR: ['ko-kr', 'ko'],
    th_TH: ['th-th', 'th'],
    pt_PT: ['pt-PT','pt-BR','pt-pt', 'pt']
  }
  
  browserLang = !!browserLang && browserLang.toLowerCase()
  if (!browserLang) return null
  const exactMatching = (list, value) => list.includes(value)
  const fuzzyMatching = (list, value) => list.some(val => value.includes(val))
  for (const method of [exactMatching, fuzzyMatching]) {
    for (const [langKey, matchingList] of Object.entries(langMap)) {
      if (method(matchingList, browserLang)) return langKey
    }
  }
  return 'en_US'
}

export default navigatorLanguageToKey