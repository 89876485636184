<script>
  import { onMount } from 'svelte'
  import { getConfig } from '@/config'
  import { getCountryInfo } from '../utils'
  import { getContext } from 'svelte';

  const { locales, language, get_Env_IpLocation } = getContext('defaultSetting')

  let countryName = getConfig()?.IP_INFO?.country || ''
  const errorCode = getConfig().errorCode

  onMount(async () => {
    const res = await fetch( `${getConfig().FE_CDN_URL}/app/CountryList/country-list.json`).then((response) => response.json())
    countryName = getCountryInfo(res)
  })
</script>

<div class="ipInfo">
  <div>
    {locales[language].yourIP}： {get_Env_IpLocation?.ip ||
      locales[language].unknown}{errorCode ? `(${errorCode})` : ''}
  </div>
  <div>
    {locales[language].yourRegion}： {countryName}
  </div>
</div>

<style lang="scss">
.ipInfo {
  div {
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
  }
}

@media screen and (min-width: 1024px) {
  .ipInfo {
    div {
      line-height: 25px;
    }
  }
}
</style>