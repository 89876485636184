<script>
  import { getContext } from 'svelte';

  const { domain, brand } = getContext('defaultSetting')
  const centerImage = `${domain}images/${brand}/ipBlock.png`;
</script>

<div class="ipBlockImage">
  <img src={centerImage} alt="block">
</div>

<style lang="scss">
.ipBlockImage {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 1024px) {
  .ipBlockImage {
    height: auto;
    max-width: 564px;
    margin: 0 auto;
  }
}
</style>