<script>
  import ContactMethods from './ContactMethods.svelte';
  import CustomService from './CustomService.svelte';
  import IpInfo from './IpInfo.svelte';
  import { getContext } from 'svelte';

  const { brand, locales, language } = getContext('defaultSetting')
</script>

<div class="ipBlockText">
  <div class="ipBlockText__box">
    <div>
      <div id="brandTitle" class="ipTitle {brand}">
        {locales[language].title}
      </div>
      <div class="content">{locales[language].content}</div>
    </div>

    <CustomService />

    {#if brand === 'vd001'}
      <ContactMethods />
    {/if}

    <IpInfo />
  </div>
</div>

<style lang="scss">
.ipBlockText {
  width: 100%;
  letter-spacing: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  text-align: center;
  color: #999999;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .ipBlockText__box {
    padding: 0 5px;
    .ipTitle {
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0;
      margin-top: 0;
      text-align: center;
      line-height: 30px;
      &.vd001, &.vd004, &.vd007, &.vd009 &.vd011 &.vd012 &.vd099{
        color: #4c9eea;
      }
      &.vd002, &.vd003, &.vd005, &.vd006, &.vd008 {
        color: #0c186c;
      }
    }
    .content {
      letter-spacing: 0;
      line-height: 25px;
      text-align: center;
      margin-top: 1.5rem;
    }
  }
}

@media screen and (min-width: 1024px) {
  .ipBlockText {
    max-width: 266px;
    justify-content: center;
    .ipBlockText__box {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>
