import './config'
import './getFingerPrint'
import App from './App.svelte'

const app = new App({
	target: document.querySelector('#app'),
});

function addMeta(){//手動新增mate標籤
    let meta = document.createElement('meta')
    meta.name = 'version'
    meta.content = `v${process?.env?.ENV_VERSION}`
    document.getElementsByTagName('head')[0].appendChild(meta);
}
addMeta()

export default app;