export const sscoreLocaleByLocale = {
    en_US: 'en', // 英文
    zh_HK: 'tw', // 繁中
    zh_CN: 'zh', // 簡中
    vi_VN: 'vie', // 越南文
    ms_MY: 'ml', // 馬來文
    ko_KR: 'kor', // 韓文
    ja_JP: 'jp', // 日文
    id_ID: 'ind', // 印尼文
    hi_IN: 'hin', // 印度文
    th_TH: 'tha', // 泰文
    pt_PT: 'bra' // 葡萄牙文
}
